import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchResponsibles(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/responsibles`, { params: queryParams })
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        fetchResponsible(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/responsible/${id}/edit`)
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        addResponsible(ctx, responsibleData) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                
                formData.append("photo", responsibleData.photo);
                Object.entries(responsibleData).map(item => {
                    if (item[0] !== "photo") {
                        if (item[0] === 'customerId'){
                            formData.append(item[0], item[1].id);
                        } else {
                            formData.append(item[0], item[1]);
                        }
                    }
                });
                
                axios
                .post(`${process.env.VUE_APP_HOST}/api/dashboard/responsible/create`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data'}
                })
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        updateResponsible(ctx, { id, responsibleData }) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
        
                formData.append('accessId', responsibleData.accessId);
                formData.append('names', responsibleData.access.names);
                formData.append('surnames', responsibleData.access.surnames);
                formData.append("photo", responsibleData.photo);
                formData.append("changePhoto", responsibleData.changePhoto);
                formData.append("initPhoto", responsibleData.initPhoto);
                formData.append("email", responsibleData.access.email);
                formData.append("phone", responsibleData.access.phone);
                formData.append("password", responsibleData.password);
                formData.append("documentNumber", responsibleData.documentNumber);
                formData.append("customerId", responsibleData.customer.id);
                formData.append("position", responsibleData.position);
        
                axios
                .put(`${process.env.VUE_APP_HOST}/api/dashboard/responsible/${id}/update`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data'}
                })
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        },
        deleteResponsible (ctx, { id }){
            return new Promise((resolve, reject) => {
                axios
                .get(`${process.env.VUE_APP_HOST}/api/dashboard/responsible/${id}/delete`)
                .then(response => resolve(response))
                .catch(error => reject(error));
            });
        }
    },
}
